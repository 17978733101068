export default {
  data() {
    return {
      
    };
  },
  methods: {
    // wallet
    async connectMetamask() {
      let _this = this
      if (window.ethereum){
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(_this.handleAccountsChanged)
          .catch((err) => {
            if (err.code === 4001) {
              // EIP-1193 userRejectedRequest error
              // If this happens, the user rejected the connection request.
              this.$toasted.error('Please connect to MetaMask.');
            } else {
              console.error(err);
            }
          });
      }else{
        this.$toasted.error('請安裝 MetaMask')
      }
    },
    async handleAccountsChanged(accounts){
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        this.$toasted.error('Please connect to MetaMask.');
      } else if (accounts[0] !== this.$store.state.account) {
        this.$store.commit('updateAccount', accounts[0]);
        this.$cookies.set('address', accounts[0]);
        window.location.reload()
      }
    },
    checkChainId(chainId){
      // let isEthereum = chainId === '0x1' || chainId === 1
      // // let isEthereum = chainId === '0x4' || chainId === 4

      let isBsc = chainId === '0x38' || chainId === 56
      // let isBsc = chainId === '0x61' || chainId === 97
      this.$store.commit('updateChainId', isBsc);
      // this.$store.commit('updateIsEth', isEthereum);
    }
  },
};
