import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toasted from 'vue-toasted';
import TBTConsump from './plugins/tbtConsump'
import TBT from './plugins/tbt'
import TBTBank from './plugins/tbtBank'

const cookies = require('vue-cookies')

Vue.config.productionTip = false

Vue.prototype.$tbtConsump = new TBTConsump()
Vue.prototype.$tbt = new TBT()
Vue.prototype.$tbtBank = new TBTBank()

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(cookies);
Vue.use(Toasted, {
  // router,
  position: 'top-center',
  duration: 2000,
});

export default app