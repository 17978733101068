// register the plugin on vue
import ABI from '@/assets/abi/tbtBank.js'
import { TBTBankAddress, rpcURL } from '@/assets/contract.js'
const Contract = require('web3-eth-contract');
Contract.setProvider(rpcURL);

export default class TBTBank {
  constructor() {
    this.contract = new Contract(ABI, TBTBankAddress);
  }

  async claim(i){
    let extraData = await this.contract.methods.claim(i)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sendTransaction(data){
    const transactionParameters = {
      to: TBTBankAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}