import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import app from '../main'
import Toasted from 'vue-toasted';
const cookies = require('vue-cookies')
Vue.use(VueRouter)
Vue.use(cookies);
Vue.use(Toasted, {
  // router,
  position: 'top-center',
  duration: 2000,
});

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
  },
  // {
  //   // will match everything
  //   path: '*',
  //   component: () => import('../views/Other.vue'),
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // Get user info from cookies
  try{
    let address = Vue.$cookies.get('address')
    if (address != undefined) {
      if ( store.state.account === '' ){
        try{
          store.commit('updateAccount', address)
        }catch(error){
          console.log('error', error)
        }
      }
    }
  }catch(error){
    console.log('error', error)
    next();
  }

  // // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
  // if (to.matched.some(record => {
  //   // console.log(record.name, record.meta.requiresAuth);
  //   return record.meta.requiresAuth;
  // })) {
  //   // 未登入
  //   if (store.state.account === ''){
  //     try{
  //       next({ name: 'Home' });
  //     }catch(error){
  //       console.log('error', error)
  //     }
  //   }else{
  //     next(); // 往下繼續執行
  //   }
  // } else {
  //   next(); // 往下繼續執行
  // }
  next();
});

export default router
