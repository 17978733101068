import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    account: '',
    chainId: false,
    nowWidth: 0,
  },
  mutations: {
    updateAccount(state, account){
      state.account = account
    },
    updateChainId(state, chainId){
      state.chainId = chainId
    },
    updateNowWidth(state, nowWidth){
      state.nowWidth = nowWidth
    },
  },
  actions: {
    async returnApi({ state }, res){
      console.log(state.a)
			await Vue.axios.post(res.url, res.data)
		},
  },
  modules: {
  }
})
