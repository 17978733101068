<template>
  <v-app class="app" dark>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import base from '@/mixin/base'
export default {
  name: 'App',
  mixins: [base],
  data: () => ({
  }),
  components:{
    
  },
  computed:{
    shortAddress(){
      return `${this.$store.state.account.slice(0, 6)}...${this.$store.state.account.slice(38)}`
    }
  },
  methods:{
    async log(){
      if (this.$store.state.account){
        this.$store.commit('clearInfo')
        this.$cookies.remove('address')
        this.$router.push({name: 'Index'})
      }else{
        if (window.ethereum) {
          await this.connectMetamask()
        } else {
          window.addEventListener('ethereum#initialized', this.connectMetamask, {
            once: true,
          });

          // If the event is not dispatched by the end of the timeout,
          // the user probably doesn't have MetaMask installed.
          setTimeout(this.connectMetamask, 2000); // 3 seconds
        }
        this.$forceUpdate()
      }
    },
  },
  async mounted(){
    // console.log('==========default==========')
    let _this = this

    this.$store.commit('updateNowWidth', document.body.clientWidth)
    window.onresize = () => {
      this.$store.commit('updateNowWidth', document.body.clientWidth)
    }

    if (window.ethereum){
      // metamask disconnect
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0){
          this.$cookies.remove('address')
          this.$store.commit('clearInfo')
          this.$toasted.error('MetaMask 連接中斷，請重新登入')
        }else{
          this.$store.commit('updateAccount', accounts[0]);
          this.$cookies.set('address', accounts[0]);
          this.$toasted.show('已切換連接錢包');
          window.location.reload()
        }
      });

      // get chain id
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      this.checkChainId(chainId)
      window.ethereum.on('chainChanged', _this.checkChainId);

      if (!window.ethereum.selectedAddress){
        let address = this.$cookies.get('address')
        if (address){
          this.$cookies.remove('address')
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import '../src/assets/scss/font.scss';
// layout
.app{
  font-family: Roboto;
  background: rgba(0, 0, 0, .8) !important;
}

// common style
.can-click{
  cursor: pointer;
}
.toasted.toasted-primary.error{
  background-color: #E53935 !important;
}
.toasted.toasted-primary.default{
  background-color: #0abbb5 !important;
}
</style>