// register the plugin on vue
import ABI from '@/assets/abi/tbt.js'
import { TBTAddress, TBTConsumpAddress, rpcURL } from '@/assets/contract.js'
const Contract = require('web3-eth-contract');
Contract.setProvider(rpcURL);

export default class TBT {
  constructor() {
    this.contract = new Contract(ABI, TBTAddress);
  }

  async getBalance(walletAddress) {
    let balance = await this.contract.methods.balanceOf(walletAddress).call();
    return parseFloat((parseInt(balance) / (10 ** 18)).toFixed(10));
  }

  async getAllowance(walletAddress){
    console.log('walletAddress', walletAddress)
    let allowance = await this.contract.methods.allowance(walletAddress, TBTConsumpAddress).call();
    return parseInt(allowance) / (10 ** 18)
  }

  async approve(){
    const num = 1000000000 * 10 ** 18;
    const numString = num.toLocaleString('fullwide', {useGrouping:false})
    let extraData =  await this.contract.methods.approve(TBTConsumpAddress, numString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async deposit(amount){
    const num = amount * 10 ** 18;
    const numString = num.toLocaleString('fullwide', {useGrouping:false})
    let extraData =  await this.contract.methods.deposit(numString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sendTransaction(data){
    const transactionParameters = {
      to: TBTAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}